function isDev1Build() {
  return process.env.NUXT_ENV_BUILD === 'dev1'
}

function isQABuild() {
  return process.env.NUXT_ENV_BUILD === 'qa3'
}

function isProductionBuild() {
  return (
    process.env.NUXT_ENV_BUILD === 'prod3' ||
    process.env.NUXT_ENV_BUILD === 'prod'
  )
}

function getBaseUrl() {
  // Don't overwrite BASE_URL if it's already set in the environment
  if (!process.env.BASE_URL) {
    if (isDev1Build()) {
      return 'https://dev1.dev-wineaccess.com'
    }
    if (isQABuild()) {
      return 'https://www.qa-wineaccess.com'
    }
    if (isProductionBuild()) {
      return 'https://www.wineaccess.com'
    }
    // Default
    return 'http://localhost:3000'
  } else {
    return process.env.BASE_URL
  }
}

module.exports = { isDev1Build, isQABuild, isProductionBuild, getBaseUrl }
