const state = () => ({
  showModal: false,
  editId: undefined,
  savedSearches: [],
})

const mutations = {
  SAVED_SEARCH_TOGGLE_SHOW_MODAL(state) {
    state.showModal = !state.showModal
  },
  SAVED_SEARCH_SET_SHOW_MODAL(state, payload) {
    state.showModal = payload
  },
  SAVED_SEARCH_SET_EDIT_ID(state, payload) {
    state.editId = payload
  },
  SAVED_SEARCH_SET_SEARCH_LIST(state, payload) {
    state.savedSearches = payload
  },
}

const actions = {
  getSavedSearches({ commit }) {
    if (!this.$auth.loggedIn) {
      return commit('SAVED_SEARCH_SET_SEARCH_LIST', [])
    }
    return this.$axios.get('api/search/user-saved-search/').then((resp) => {
      commit('SAVED_SEARCH_SET_SEARCH_LIST', resp.data.results)
    })
  },
  toggleSavedSearchModal({ commit }, payload) {
    // Order matters
    commit('SAVED_SEARCH_SET_EDIT_ID', payload)
    commit('SAVED_SEARCH_TOGGLE_SHOW_MODAL')
  },
  setSavedSearchModal({ commit }, { editId, showModal }) {
    // Order matters
    commit('SAVED_SEARCH_SET_EDIT_ID', editId)
    commit('SAVED_SEARCH_SET_SHOW_MODAL', showModal)
  },
}

const getters = {
  savedSearchList(state) {
    return state.savedSearches
  },
  saveSearchShowModal(state) {
    return state.showModal
  },
  savedSearchEditId(state) {
    return state.editId
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
