const state = () => ({
  cart: {},
  cartIsLoading: false,
  pptoCart: {},
  toggleCart: false,
  toggleLeftMenu: false,
  backdropEnabled: true,
  pptoReviewState: false,
})

const mutations = {
  SET_CART(state, payload) {
    state.cart = payload
  },
  SET_CART_IS_LOADING(state, loading) {
    state.cartIsLoading = loading
  },
  SET_PPTO_CART(state, payload) {
    state.pptoCart = payload
  },
  UPDATE_LINE_QTY(state, payload) {
    state.cart.lines[payload.index].quantity = payload.quantity
  },
  UPDATE_PPTO_LINE_QTY(state, payload) {
    state.pptoCart.lines[payload.index].quantity = payload.quantity
  },
  REFRESH_CART(state) {
    this.$axios.get('/api/basket/').then((resp) => {
      state.cart = resp.data
    })
  },
  async SET_PPTO_CART_ORDER_ID(state, orderId) {
    try {
      await this.$axios
        .patch('/api/basket/ppto/', { post_purchase_order: orderId })
        .then((resp) => {
          state.pptoCart = resp.data
        })
    } catch (e) {
      console.error(e)
      this.$router.push('/accounts/orders/')
    }
  },
  UPDATE_CART(state, payload) {
    state.cart = payload
  },
  UPDATE_PPTO_CART(state, payload) {
    state.pptoCart = payload
  },
  TOGGLE_CART(state, disableBackdrop) {
    state.backdropEnabled = !disableBackdrop
    state.toggleCart = !state.toggleCart
  },
  TOGGLE_LEFT_MENU(state) {
    state.toggleLeftMenu = !state.toggleLeftMenu
  },
  ENABLE_CART_BACKDROP(state, payload) {
    state.backdropEnabled = payload
  },
  SET_PPTO_REVIEW_STATE(state, payload) {
    state.pptoReviewState = payload
  },
}

const actions = {
  getCart({ commit, dispatch }) {
    return this.$axios.get('/api/basket/').then((resp) => {
      commit('SET_CART', resp.data)
      dispatch('getPromoBanner')
    })
  },
  setCartLoading({ commit }, loading) {
    commit('SET_CART_IS_LOADING', loading)
  },
  getPPTOCart({ commit, dispatch }) {
    return this.$axios.get('/api/basket/ppto/').then((resp) => {
      commit('SET_PPTO_CART', resp.data)
    })
  },
  async addToCart({ commit, state, dispatch }, payload) {
    try {
      const params = payload.isCheckout ? { checkout: 1 } : {}
      const resp = await this.$axios({
        method: 'POST',
        url: '/api/basket/add-product/',
        data: payload,
        params,
      })
      if (!state.toggleCart && payload.toggleCart !== false) {
        commit('TOGGLE_CART', payload.disableBackdrop)
      }
      commit('UPDATE_CART', resp.data)
      if (payload.isCheckout) dispatch('hydrateCheckoutState', state.cart)
      else dispatch('getPromoBanner')
    } catch (e) {
      throw e.response.data
    }
  },
  async addToPPTOCart({ commit, state, dispatch }, payload) {
    try {
      const params = payload.isCheckout ? { checkout: 1 } : {}
      const resp = await this.$axios({
        method: 'POST',
        url: '/api/basket/add-product-ppto/',
        data: payload,
        params,
      })
      commit('UPDATE_PPTO_CART', resp.data)
    } catch (e) {
      throw e.response.data
    }
  },
  async addCurrentCartToPPTOCart(
    { commit, state, dispatch },
    { orderId, basketId }
  ) {
    try {
      const orderAssignResp = await this.$axios.patch('/api/basket/ppto/', {
        post_purchase_order: orderId,
      })
      commit('UPDATE_PPTO_CART', orderAssignResp.data)
    } catch (e) {
      throw e.response.data
    }
    try {
      const basketAddResp = await this.$axios({
        method: 'POST',
        url: '/api/basket/add-product-ppto/',
        data: { basket: basketId, post_purchase_order: orderId },
      })
      commit('UPDATE_PPTO_CART', basketAddResp.data)
      return commit('REFRESH_CART')
    } catch (e) {
      throw e.response.data
    }
  },
  async updateCartLine({ commit, state, dispatch }, payload) {
    // User can still be clicking + or - into the cart. Wait a second to let them
    // finish before updating the cart
    const params = payload.isCheckout ? { checkout: 1 } : {}
    try {
      const resp = await this.$axios({
        method: 'PATCH',
        url: `/api/basket/${state.cart.id}/lines/${payload.line_id}/`,
        data: {
          quantity: payload.quantity,
          source_url: payload.source_url,
        },
        params,
      })
      if (!state.toggleCart && payload.toggleCart) {
        commit('TOGGLE_CART')
      }
      commit('UPDATE_CART', resp.data)
      if (!payload.isCheckout) dispatch('getPromoBanner')
    } catch (e) {
      throw e.response.data
    }
  },
  toggleCart({ commit }) {
    commit('TOGGLE_CART')
  },
  toggleLeftMenu({ commit }) {
    commit('TOGGLE_LEFT_MENU')
  },
  setPPTOReviewState({ commit }, payload) {
    commit('SET_PPTO_REVIEW_STATE', payload)
  },
  updateLineQuantity({ commit }, payload) {
    commit('UPDATE_LINE_QTY', payload)
  },
  updatePPTOLineQuantity({ commit }, payload) {
    commit('UPDATE_PPTO_LINE_QTY', payload)
  },
  updateCart({ commit, state, dispatch }, payload) {
    commit('UPDATE_CART', payload)
    dispatch('hydrateCheckoutState', state.cart)
  },
  updatePPTOCart({ commit, dispatch }, payload) {
    commit('UPDATE_PPTO_CART', payload)
  },
  setPPTOCartOrderId({ commit, dispatch }, orderId) {
    if (orderId) {
      commit('SET_PPTO_CART_ORDER_ID', orderId)
    }
  },
}

const getters = {
  cart(state) {
    return state.cart
  },
  cartIsLoading(state) {
    return state.cartIsLoading
  },
  pptoCart(state) {
    return state.pptoCart
  },
  toggleCart: (state) => state.toggleCart,
  toggleLeftMenu: (state) => state.toggleLeftMenu,
  backdropEnabled: (state) => state.backdropEnabled,
  pptoReview: (state) => state.pptoReviewState,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
